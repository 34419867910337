/** @odoo-module */

import { Component } from "@odoo/owl";

class ProfilingSystrayItem extends Component {}
ProfilingSystrayItem.template = "web.ProfilingSystrayItem";

export const profilingSystrayItem = {
    Component: ProfilingSystrayItem,
};
