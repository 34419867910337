/*odoo.define("core_pettracer.website_sale_cart_lines_extended", function (require) {
    "use strict";

    var publicWidget = require('web.public.widget');

    publicWidget.registry.websiteSalesCartLinesForSavings = publicWidget.Widget.extend({

        template: 'website_sales.cart_lines',
        selector: ".oe_cart",

        start: function() {
            var self = this;
            return this._super.apply(arguments).then(function() {

                setInterval(function() {
                    $(".show_savings").each(function(idx) {
                        $( this ).parent().find(".text-danger").hide();
                    });
                },100);



            });
        },

    });
});*/

odoo.define('core_pettracer.snippet_editor_inherit', function (require) {
'use strict';

    const weSnippetEditor = require('web_editor.snippet.editor');

    const wSnippetMenu = weSnippetEditor.SnippetsMenu.extend({

        _computeSnippetTemplates: function (html) {
        console.log("Inherited by pettracer ###")
        /*
            var self = this;
            var $html = $(html);
            this._patchForComputeSnippetTemplates($html);
            var $scroll = $html.siblings('#o_scroll');

            this.templateOptions = [];
            var selectors = [];
            var $styles = $html.find('[data-selector]');
            const snippetAdditionDropIn = $styles.filter('#so_snippet_addition').data('drop-in');
            const oldFooterSnippetsSelector = 'footer .oe_structure > *';
            const newFooterSnippetsSelector = 'footer #footer.oe_structure > *:not(.s_popup)';
            $styles.each(function () {
                var $style = $(this);
                var selector = $style.data('selector');
                var exclude = $style.data('exclude') || '';
                const excludeParent = $style.attr('id') === "so_content_addition" ? snippetAdditionDropIn : '';

                // TODO to remove in master: the Carousel snippet has a `content`
                // class in its `.row` elements which makes dropzones appear when
                // dragging inner content, allowing them to be dropped in the row,
                // where it should not be the case.
                if ($style[0].getAttribute('id') === 'so_content_addition') {
                    let dropInPatch = $style[0].dataset.dropIn.split(', ');
                    dropInPatch = dropInPatch.map(selector => selector === '.content' ? '.content:not(.row)' : selector);
                    $style[0].dataset.dropIn = dropInPatch.join(', ');
                }

                // Fix in stable: we have removed the option for setting the
                // background color for snippets in the footer. However, this should
                // not affect the snippets in the "All pages" popup which is also
                // located in the footer.
                if (($style[0].dataset.js === 'ColoredLevelBackground') && exclude) {
                    exclude = exclude
                        .split(', ')
                        .map(selector => selector === oldFooterSnippetsSelector ? newFooterSnippetsSelector : selector)
                        .join(', ');
                }
                if (($style[0].dataset.js === 'BackgroundToggler')) {
                    selector = selector
                        .split(', ')
                        .map(selector => selector === oldFooterSnippetsSelector ? newFooterSnippetsSelector : selector)
                        .join(', ');
                }

                var target = $style.data('target');
                var noCheck = $style.data('no-check');
                var optionID = $style.data('js') || $style.data('option-name'); // used in tour js as selector
                var option = {
                    'option': optionID,
                    'base_selector': selector,
                    'base_exclude': exclude,
                    'base_target': target,
                    'selector': self._computeSelectorFunctions(selector, exclude, target, noCheck),
                    '$el': $style,
                    'drop-near': $style.data('drop-near') && self._computeSelectorFunctions($style.data('drop-near'), '', false, noCheck, true, excludeParent),
                    'drop-in': $style.data('drop-in') && self._computeSelectorFunctions($style.data('drop-in'), '', false, noCheck),
                    'data': _.extend({string: $style.attr('string')}, $style.data()),
                };
                self.templateOptions.push(option);
                selectors.push(option.selector);
            });
            $styles.addClass('d-none');

            globalSelector.closest = function ($from) {
                var $temp;
                var $target;
                for (var i = 0, len = selectors.length; i < len; i++) {
                    $temp = selectors[i].closest($from, $target && $target[0]);
                    if ($temp.length) {
                        $target = $temp;
                    }
                }
                return $target || $();
            };
            globalSelector.all = function ($from) {
                var $target = $();
                for (var i = 0, len = selectors.length; i < len; i++) {
                    $target = $target.add(selectors[i].all($from));
                }
                return $target;
            };
            globalSelector.is = function ($from) {
                for (var i = 0, len = selectors.length; i < len; i++) {
                    if (selectors[i].is($from)) {
                        return true;
                    }
                }
                return false;
            };

            this.$snippets = $scroll.find('.o_panel_body').children()
                .addClass('oe_snippet')
                .each((i, el) => {
                    const $snippet = $(el);
                    const name = _.escape(el.getAttribute('name'));
                    const thumbnailSrc = _.escape(el.dataset.oeThumbnail);
                    console.log($snippet)
                    const $sbody = $snippet.children().addClass('oe_snippet_body');
                    const isCustomSnippet = !!el.closest('#snippet_custom');

                    // Associate in-page snippets to their name
                    // TODO I am not sure this is useful anymore and it should at
                    // least be made more robust using data-snippet
                    let snippetClasses = $sbody.attr('class').match(/s_[^ ]+/g);
                    if (snippetClasses && snippetClasses.length) {
                        snippetClasses = '.' + snippetClasses.join('.');
                    }
                    const $els = self.$body.find(snippetClasses).not('[data-name]').add($(snippetClasses)).add($sbody);
                    $els.attr('data-name', name).data('name', name);

                    // Create the thumbnail
                    const $thumbnail = $(`
                        <div class="oe_snippet_thumbnail">
                            <div class="oe_snippet_thumbnail_img" style="background-image: url(${thumbnailSrc});"/>
                            <span class="oe_snippet_thumbnail_title">${name}</span>
                        </div>
                    `);
                    $snippet.prepend($thumbnail);

                    // Create the install button (t-install feature) if necessary
                    const moduleID = $snippet.data('moduleId');
                    if (moduleID) {
                        el.classList.add('o_snippet_install');
                        $thumbnail.append($('<button/>', {
                            class: 'btn btn-primary o_install_btn w-100',
                            type: 'button',
                            text: _t("Install"),
                        }));
                    }

                    // Create the rename and delete button for custom snippets
                    if (isCustomSnippet) {
                        const btnRenameEl = document.createElement('we-button');
                        btnRenameEl.dataset.snippetId = $snippet.data('oeSnippetId');
                        btnRenameEl.classList.add('o_rename_btn', 'fa', 'fa-pencil', 'btn', 'o_we_hover_success');
                        btnRenameEl.title = _.str.sprintf(_t("Rename %s"), name);
                        $snippet.append(btnRenameEl);
                        const btnEl = document.createElement('we-button');
                        btnEl.dataset.snippetId = $snippet.data('oeSnippetId');
                        btnEl.classList.add('o_delete_btn', 'fa', 'fa-trash', 'btn', 'o_we_hover_danger');
                        btnEl.title = _.str.sprintf(_t("Delete %s"), name);
                        $snippet.append(btnEl);
                    }
                })
                .not('[data-module-id]');

            // Enable the snippet tooltips
            this.$snippets.tooltip({
                trigger: 'manual',
                placement: 'bottom',
                title: _t("Drag and drop the building block."),
                // Ensure the tooltips have a good position when in iframe.
                container: this.el,
                // Prevent horizontal scroll when tooltip is displayed.
                boundary: this.el.ownerDocument.body,
            });

            // Hide scroll if no snippets defined
            if (!this.$snippets.length) {
                this.$el.detach();
            }

            // Register the text nodes that needs to be auto-selected on click
            this._registerDefaultTexts();

            // Force non editable part to contentEditable=false
            $html.find('.o_not_editable').attr('contentEditable', false);

            // Add the computed template and make elements draggable
            this.$el.html($html);
            this.$el.append(this.customizePanel);
            this.$el.append(this.invisibleDOMPanelEl);
            this._makeSnippetDraggable(this.$snippets);
            this._disableUndroppableSnippets();

            this.$el.addClass('o_loaded');
            $(this.el.ownerDocument.body).toggleClass('editor_has_snippets', !this.folded);
        */},

    });
});
