
jQuery(document).ready(function (){
    jQuery('#review-carousel').carousel({
        'interval': 6000,
        'wrap': true
    })

    jQuery('[data-toggle="popover"]').popover();
})

jQuery(document).ready(function (){
    jQuery('#subscription-required').attr('data-content',  jQuery('#subscription-required').attr('alt'));
})